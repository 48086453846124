<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { Availability } from '@containex/portal-backend-dto';
    import { differenceInHours, parseISO } from 'date-fns';

    defineProps<{
        productAvailable: Availability;
        completionDate: string | undefined;
        onDemandCompletionDate: string | null | undefined;
    }>();

    const { t } = useI18n();

    function getNumberOfWeeksTillCompletion(completionDate: string): number {
        const parsedCompletionDate = parseISO(completionDate);
        const currentDate = new Date();
        // eslint-disable-next-line no-magic-numbers
        const timeDifferenceInWeeks = Math.ceil(differenceInHours(parsedCompletionDate, currentDate) / (7 * 24));

        return timeDifferenceInWeeks < 1 ? 1 : timeDifferenceInWeeks;
    }

    function getNumberOfWeeksTillCompletionString(completionDate: string): string {
        const numberOfWeeks = getNumberOfWeeksTillCompletion(completionDate);

        if (numberOfWeeks === 1) {
            return t('PRODUCT.DELIVERY_AVAILABILITY.DELIVERY_IN_ONE_WEEK');
        }
        return t('PRODUCT.DELIVERY_AVAILABILITY.DELIVERY_IN_X_WEEKS', {
            numberOfWeeks,
        });
    }
</script>

<template>
    <div v-if="productAvailable == Availability.Available">
        <i class="cart-item-delivery-icon-available-now pi pi-circle-fill"></i>
        {{ t('PRODUCT.DELIVERY_AVAILABILITY.DELIVERY_NOW') }}
    </div>
    <div v-else-if="productAvailable == Availability.Backordered">
        <i class="cart-item-delivery-icon-available-in-x pi pi-circle-fill"></i>
        {{ getNumberOfWeeksTillCompletionString(completionDate ?? '') }}
    </div>
    <div v-else-if="productAvailable == Availability.OnDemand">
        <i class="cart-item-delivery-icon-available-in-x pi pi-circle-fill"></i>
        {{ getNumberOfWeeksTillCompletionString(onDemandCompletionDate ?? '') }}
    </div>
    <div v-else-if="productAvailable == Availability.NotAvailable">
        <i class="cart-item-delivery-icon-not-available pi pi-circle"></i>
        {{ t('PRODUCT.DELIVERY_AVAILABILITY.NOT_AVAILABLE') }}
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .cart-item-delivery-icon-available-now {
        color: main.$color-order-successful;
    }

    .cart-item-delivery-icon-available-in-x {
        color: main.$color-yellow-500;
    }

    .cart-item-delivery-icon-not-available {
        color: main.$color-product-not-available;
    }
</style>
