<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { DepotType } from '@containex/portal-backend-dto';
    import { computed } from 'vue';

    const props = withDefaults(
        defineProps<{
            depotType: DepotType;
            depotLocation: string | null | undefined;
            additionalLocations?: number;
        }>(),
        {
            additionalLocations: 0,
        }
    );

    const { t } = useI18n();

    const additionalLocationString = computed(() => {
        if (props.additionalLocations <= 0) {
            return '';
        } else if (props.additionalLocations === 1) {
            return ` +${String(props.additionalLocations)} ${t('PRODUCT.ONE_DEPOT')}`;
        }
        return ` +${String(props.additionalLocations)} ${t('PRODUCT.MULTIPLE_DEPOTS')}`;
    });
</script>

<template>
    <div class="item-location">
        <template v-if="depotType === DepotType.ProductionSite || depotLocation == null">
            <div>
                <i class="pi pi-map-marker"></i>
                {{ t('PRODUCT.SHIPPING_DETAILS.SHIPPING_INCLUDED') }}
                <span class="text-md-bold-line-height-auto">{{ additionalLocationString }}</span>
            </div>
        </template>
        <template v-else>
            <div>
                <i class="pi pi-building"></i>
                {{ depotLocation }}
                <span class="text-md-bold-line-height-auto">{{ additionalLocationString }}</span>
            </div>
        </template>
    </div>
</template>
