<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { DepotType } from '@containex/portal-backend-dto';

    defineProps<{
        depotType: DepotType;
        depotLocation: string | null | undefined;
    }>();

    const { t } = useI18n();
</script>

<template>
    <div class="price-information">
        <template v-if="depotType == DepotType.ProductionSite">
            {{ t('PRODUCT.PRICE_INFO_PRODUCTION_SITE', { location: t('PRODUCT.FROM_FACTORY_LEADING_LETTER_SMALL') }) }}
        </template>
        <template v-else>
            {{ t('PRODUCT.PRICE_INFO_DEPOT', { location: depotLocation }) }}
        </template>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .price-information {
        color: main.$color-secondary;
    }
</style>
