import type { Cart, CtxProductVariant, LineItem } from '@containex/portal-backend-api-client';
import { Availability, DepotType } from '@containex/portal-backend-dto';

export function getAvailabilityForVariantBasedOnCart(
    variant: CtxProductVariant,
    cart: Cart | null,
    next: boolean
): Availability {
    if (cart == null) {
        return getAvailabilityForVariant(variant);
    }

    const lineItem = cart.items.find((item) => item.variant_id === variant.id) ?? null;
    return getAvailabilityForVariantBasedOnCartItem(variant, lineItem, next);
}

export function getAvailabilityForVariantBasedOnCartItem(
    variant: CtxProductVariant,
    lineItem: LineItem | null,
    next: boolean
): Availability {
    if (lineItem == null) {
        return getAvailabilityForVariant(variant);
    }

    const currentQuantity = next ? lineItem.quantity + 1 : lineItem.quantity;
    const quantityAvailable = variant.depotQuantity.quantity_available;
    const quantityOrdered = variant.depotQuantity.quantity_ordered;
    const hasSomethingOrdered = variant.depotQuantity.completion_date != null;
    const onDemandPossible = variant.depotQuantity.on_demand_completion_date != null;

    if (quantityAvailable > 0 && currentQuantity <= quantityAvailable) {
        return Availability.Available;
    } else if (quantityOrdered > 0 && hasSomethingOrdered && currentQuantity <= quantityAvailable + quantityOrdered) {
        return Availability.Backordered;
    } else if (onDemandPossible) {
        return Availability.OnDemand;
    }

    return Availability.NotAvailable;
}

export function getAvailabilityForVariant(variant: CtxProductVariant): Availability {
    if (variant.depotQuantity.quantity_available > 0) {
        return Availability.Available;
    } else if (variant.depotQuantity.quantity_ordered > 0 && variant.depotQuantity.completion_date != null) {
        return Availability.Backordered;
    } else if (
        variant.depotQuantity.depot.type === DepotType.ProductionSite &&
        variant.depotQuantity.on_demand_completion_date != null
    ) {
        return Availability.OnDemand;
    }

    return Availability.NotAvailable;
}
